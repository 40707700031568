// Home Hero Block Styling

.hero-block {
    background-color: #92c4ee;
    background: radial-gradient(ellipse at center, #92c4ee 1%, #4081bd 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#92c4ee', endColorstr='#4081bd', GradientType=1);
}

.hero-block-content {
    min-height: calc(100vh - 148px);
    background: url(/images/RUUD_furnace_AC.webp) no-repeat;
    background-position: center 300px;
    background-size: contain;
    display: grid;
    grid-gap: 3%;
    padding-top: 50px;

    p{
        font-weight: bold;
        line-height: 1.4;
    }

    h1 {
        color: #fff;
        margin: 0;
    }


    @media screen and (max-width: 767px) {
        display: block;
        padding-top: 20px;
        
        & p{
            text-shadow: 0px 0px 15px rgba(255,255,255,1);
            color: #000;
        }
    }

    @media screen and (min-width: 767px) {
        grid-template-columns: 39% 57%;
    }

    &__image img {
        max-width: 50%;
        float: right;

        @media screen and (max-width: 767px) {
            max-width: 20%;
            float: none;
        }
    }
}

// Big Header styling
header{
    border-bottom: 1px solid #f1f1f1;
    background: #fcfcfc;

    @media screen and (min-width: 1025px){
        padding-bottom: 20px;
    }
}

a {
    text-decoration: none;
}

// Top Bar
.top-bar,
.nav-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px 0;

    @media screen and (max-width: 1024px) {
        padding: 10px 20px;
    }
}

.top-bar {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    font-size: 1.5rem;

    @media screen and (max-width: 1023px){
        display: none;
    }

    & .social {
        color: #4081bd;

    }

   


}


.nav-bar {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    & .branding {
        max-width: 280px;

        & img {
            max-width: 100%;
        }
    }

    & ul.nav {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        list-style-type: none;
        margin: 0;
        padding: 0;

        @media screen and (max-width: 1023px){
            display: none;
        }

        & li{
            padding: 0 0 0 20px;

            a{
                font-weight: bold;
                color: #333;

                &:hover{
                    color: #4081bd;
                }
            }
        }
    }

}
.da {
    font-size: 1rem;
    margin-top: 20px;

    &__btn {
        padding: 7px 15px;
        color: #fff;
        

        

        &--call {
            background: #ed3a42;

            &:hover {
                background: #c9262e;

            }
        }

        &--quote {
            background: #36912f;

            &:hover {
                background: #22701c;
            }

            @media screen and (min-width: 1024px) {
                margin-left: 20px;
            }
        }
    }

    @media screen and (max-width: 767px){
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;

        &__btn{
            text-align: center;
        }
    }

}

// Footer

footer{
    margin-top: 40px;
    background: #fcfcfc;
    border-top: 1px solid #f1f1f1;
    padding: 40px 0 0;
    position: relative;

    a{
        color: #333;
    }

    @media screen and (min-width: 1024px){
        font-size: .75rem;
    }


}

.business-info{

    &__name{
        font-weight: bolder;;
    }
}

.accredations{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.credits{
    margin-top: 40px;
    background: #333;
    position: relative;
    bottom: 0;
    padding: 1rem 0;
    font-size: .8rem;
    color: #fff;
    
    a{
        color: #fff;
    }
}

// Review Block

.review{
    padding: 20px;
    // border-radius: 10px;
    position: relative;

    &:nth-of-type(even){
        background: #f1f1f1;
    }

    // .review-photo{
    //     text-align: center;
    //     margin-top: -100px;
    // }

    &:last-child{
        margin-bottom: 20px !important;
    }
}

.review-separator{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-style: italic;
    color: #fff;
    background-color: #4081bd;
    // background: radial-gradient(ellipse at center, #92c4ee 1%, #4081bd 100%);
    // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#92c4ee', endColorstr='#4081bd', GradientType=1);
    margin-top: -100px;
    text-shadow: -1px 1px 2 rgba(0,0,0,.8), 1px 1px 2 rgba(0,0,0,.8), 1px -1px 2 rgba(0,0,0,.8), -1px -1px 2 rgba(0,0,0,.8);
    position: relative;
}

.review-btn{
    align-items: center;
    padding: 20px;
    // font-size: 300%;
    // font-family: 'Merriweather', serif;
    background: #36912f;
    color: #fff;
    text-align: center;
    display: block;

        &:hover {
            background: #22701c;
        }
}

// Cards

.card{

    display: grid;
    overflow: hidden;

    &__head{
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
        background-color: #4081bd;
        color: #fff;
    }

    &__body{
        padding: 0 20px;
        background: #f1f1f1;
        @media screen and (min-width: 769px){
            min-height: 320px;
        }
        
    }

    &__link a{
        background: #36912f;
        color: #fff;
        text-align: center;
        display: block;
        font-weight: bold;
        padding: 17px 0;
        text-transform: uppercase;
    }

    @media screen and (max-width: 1024px){
        margin-bottom: 40px;
    }
}

.flip-card{
    position: relative;
	width: 100%;
	height: 8rem;
	margin: 0.5rem;
	text-align: center;
	line-height: 8rem;
	color: #ecf0f1;
	border-radius: 0.8rem;
 transform-style: preserve-3d;
}

.face {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 0.8rem;
	background-size: cover;
	background-position: center center;
	backface-visibility: hidden;
	transition: transform 0.5s ease-in-out;
}

.face--front {
	background-image:url(/images/Rheem-3-ton-outdoor-unit.jpg);
	font-size: 3rem;
}

.face--back {
	background: #2c3e50;
	font-size: 1.5rem;
	transform: rotateY(180deg);
}

.flip-card:hover .face--front {
	transform: rotateY(-180deg);
}

.flip-card:hover .face--back {
	transform: rotateY(0deg);
}

// CTAs

.cta-service{
    background:linear-gradient(
        rgba(0, 0, 0, 0.6), 
        rgba(0, 0, 0, 0.6)
      ),url(/images/changing_seasons.jpg) no-repeat;
    background-size: cover;
    background-position: center center;
    color: #fff;

    @media screen and (min-width: 769px){
        margin-bottom: -100px;
    }

    @media screen and (max-width: 768px){
        margin-bottom: -50px;
    }

    form{
        // color: #333;
        background: none;

        .service-cta-fields{
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 20px;
        }
        

        button{
            margin-top: 20px;
            text-transform: uppercase;
            font-weight: bold;
        }

        input{
            background: rgba(255,255,255,.7);
        }
    }
    

    & .fixed-row{
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

// Breadcrumbs

.breadcrumbs{
    color: #888;

    a{
        color: #888;

        &:hover{
            color: #4081bd;
        }
    }
    
}

// Related Posts List

.related{
    text-align: center;

    & ul{
        list-style-type: none;
        margin: 0;
        padding: 0;
        li{
            display: inline;
            padding: 0 20px;
        }
        
    }
}

// Prospects

.prospect-list{

    div{
        margin: 10px 0;
    }

    .head-row, .prospect{
        display: grid;
        grid-gap: 10px;

        @media screen and (min-width: 769px){
            grid-template-columns: repeat(4, 1fr)
        }
    }

    .head-row{
        font-weight: bold;
    }

    .prospect{
        
        &:nth-of-type(even){
            background: #f1f1f1;
        }
    }
}