// Reset
html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

body {
    margin: 0;
}

img {
    display: block;
    max-width: 100%;
}

// HTML defaults



// Utility Classes


.tb-margin {
    @media screen and (min-width: 769px){
        margin-top: 100px !important;
        margin-bottom: 100px !important;
    }
    
}


.mobile-tb-margin{
    @media screen and (max-width: 768px){
        margin-top: 100px !important;
        margin-bottom: 100px !important;
    }
}

.float-left {
    @media screen and (min-width: 768px) {
        float: left;
        margin-right: 20px;
    }

    @media screen and (max-width: 767px) {
        width: 100%;
    }

}

.float-right {
    float: right;
    margin-left: 20px;
}

.clear-float {
    clear: both;
}

.center-text-divider {
    text-align: center;
    position: relative;
    // font-family: 'Merriweather', serif;
    font-size: 1.5rem;
    font-weight: bold;
    margin: 40px 0;

    & span {
        background: #fff;
        padding: 0 0.25rem;
    }

    & :before {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        top: 50%;
        width: 100%;
        border-bottom: 2px solid #666;
    }
}

.center-text {
    text-align: center;
}

.right-text {
    text-align: right;
}

.justify-text {
    text-align: justify;
}

.fixed-row {
    max-width: 1100px;
    margin: auto auto;

    @media screen and (max-width: 1024px) {
        padding: 0 2.5vh;
    }
}

.no-overflow{
    overflow: hidden;
}

.no-hand{
    cursor: default;
}

.round{
    width: 150px;
    height: 150px;
    border-radius: 50%;

    img{
        -o-object-fit: cover;
           object-fit: cover;
    }
}

.b-shadow {
    border-radius: 5px;
    -webkit-box-shadow: 0 2.598px 4.7px 0.3px rgba(0, 0, 0, 0.12), 0 1.732px 5.88px 0.12px rgba(0, 0, 0, 0.08);
    box-shadow: 0 2.598px 4.7px 0.3px rgba(0, 0, 0, 0.12), 0 1.732px 5.88px 0.12px rgba(0, 0, 0, 0.08);

    &:hover {
        -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    }
}

.b-shadow-nohover {
    border-radius: 5px;
    -webkit-box-shadow: 0 2.598px 4.7px 0.3px rgba(0, 0, 0, 0.12), 0 1.732px 5.88px 0.12px rgba(0, 0, 0, 0.08);
    box-shadow: 0 2.598px 4.7px 0.3px rgba(0, 0, 0, 0.12), 0 1.732px 5.88px 0.12px rgba(0, 0, 0, 0.08);

}

.b-shadow-noradius {
    -webkit-box-shadow: 0 2.598px 4.7px 0.3px rgba(0, 0, 0, 0.12), 0 1.732px 5.88px 0.12px rgba(0, 0, 0, 0.08);
    box-shadow: 0 2.598px 4.7px 0.3px rgba(0, 0, 0, 0.12), 0 1.732px 5.88px 0.12px rgba(0, 0, 0, 0.08);

}

.three-one-grid {
    display: grid;
    grid-gap: 20px;

    @media screen and (min-width: 768px) {
        grid-template-columns: 3fr 1fr;
    }
}

.one-col-grid {
    display: grid;
    grid-template-columns: 1fr;
}

.two-col-grid {
    display: grid;
    grid-gap: 20px;

    @media screen and (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }
}

.xs-two-col-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.three-col-grid {
    display: grid;
    grid-gap: 20px;

    @media screen and (min-width: 769px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.auto-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, 100px);
    grid-gap: 10px;
}

@media screen and (min-width: 767px) {
    .phone-only {
        display: none;
    }
}

.iframe-container {
    overflow: hidden;
    // Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 0.5625)
    padding-top: 100%;
    position: relative;
}

.iframe-container iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

/*** Form Styling ***/

/* ----------------------------------------------------------------------------------------------------
Super Form Reset
A couple of things to watch out for:
- IE8: If a text input doesn't have padding on all sides or none the text won't be centered.
- The default border sizes on text inputs in all UAs seem to be slightly different. You're better off using custom borders.
- You NEED to set the font-size and family on all form elements
- Search inputs need to have their appearance reset and the box-sizing set to content-box to match other UAs
- You can style the upload button in webkit using ::-webkit-file-upload-button
- ::-webkit-file-upload-button selectors can't be used in the same selector as normal ones. FF and IE freak out.
- IE: You don't need to fake inline-block with labels and form controls in IE. They function as inline-block.
- By turning off ::-webkit-search-decoration, it removes the extra whitespace on the left on search inputs
----------------------------------------------------------------------------------------------------*/

input,
label,
select,
button,
textarea {
    margin: 0;
    border: 0;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    background: none;
    line-height: 1;

    /* Browsers have different default form fonts */
    font-size: 13px;
    font-family: Arial;
}

/* Remove the stupid outer glow in Webkit */
input:focus {
    outline: 0;
}

/* Box Sizing Reset
-----------------------------------------------*/

/* All of our custom controls should be what we expect them to be */
input,
textarea {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

/* These elements are usually rendered a certain way by the browser */
button,
input[type=reset],
input[type=button],
input[type=submit],
input[type=checkbox],
input[type=radio],
select {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

/** Our Form Styling **/
form {

    background: #f1f1f1;
    padding: 20px;

    ::-webkit-input-placeholder{
        color: #999;
    }

    :-ms-input-placeholder{
        color: #999;
    }

    ::-ms-input-placeholder{
        color: #999;
    }

    ::placeholder{
        color: #999;
    }

    label,
    input,
    textarea {
        font-size: .9rem;
        line-height: 1.5;
    }

    input[type=text],
    input[type=email] {
        // border-bottom: 1px solid #666;
        width: 100%;
        margin-bottom: .25rem;
        background: #fff;
        padding: 5px;

        &:focus{
            background: #fdfce6;
        }
    }

    input[type=radio] {
        background: #fff !important;
    }

    .required:after {
        content: '*';
        color: red;
    }

    textarea {
        background: #fff;
        border-radius: 5px;
        padding: 5px;

        &:focus{
            background: #fdfce6;
        }
    }

    .submit {
        text-align: right;
    }

    button {

        padding: 7px 15px;
        border-radius: 5px;
        color: #fff;
        font-size: .9rem;
        cursor: pointer;

        &[type=submit] {
            background: #36912f;


            &:hover {
                background: #22701c;
            }

        }
        &[type=reset]{

            background: #aaa;
    
            &:hover{
                background: #666;
            }
        }

    }

}