// Image styling & alignment
.md-right-float{
    @media screen and (min-width: 1024px){
        float: right;
        width: 50%;
        padding-left: 20px;
    }
}
.md-left-float{
    @media screen and (min-width: 1024px){
        float: left;
        width: 50%;
        padding-right: 20px;
    }
}