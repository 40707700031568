// Perfundo Pure CSS Lightbox
// url: https://perfundo.oberlehner.net/
// Author: Markus Oberlehner
// author url: https://markus.oberlehner.net/
// Modifications: David Wyatt https://sitespdq.com


.perfundo__overlay {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    visibility: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .9)
}

.perfundo__overlay.is-active,
.perfundo__overlay:target {
    visibility: visible
}

.perfundo__content {
    max-height: 100%;
    overflow: auto
}

.is-active>.perfundo__content,
:target>.perfundo__content {
    -webkit-animation: .4s ease-out .2s both;
    animation: .4s ease-out .2s both;
    -webkit-animation-name: inherit;
    animation-name: inherit
}

.perfundo__html {
    padding: 2em;
    max-width: 42em;
    background-color: #fff
}

.perfundo__figure {
    display: none;
    margin: 1.5em
}

.is-active>.perfundo__figure,
:target>.perfundo__figure {
    display: block
}

.perfundo__figure img {
    display: block;
    height: 0
}

.perfundo__figcaption {
    color: #fff
}

.perfundo__image {
    max-width: 100%;
    background-size: cover;
    background-position: center center
}

.perfundo__control {
    position: absolute
}

.perfundo__control,
.perfundo__control:visited {
    color: #fff
}

.perfundo__close {
    top: 1em;
    right: 1em
}

.perfundo__prev {
    left: 2em
}

.perfundo__next {
    right: 2em
}

.perfundo__untarget {
    position: fixed;
    top: 0
}

.perfundo__next,
.perfundo__prev {
    top: 90%;
    margin-top: -1.5em;
    opacity: 1;
    -webkit-transition: opacity 1s;
    transition: opacity 1s
}

.perfundo__next:focus,
.perfundo__next:hover,
.perfundo__prev:focus,
.perfundo__prev:hover {
    opacity: 1
}

.perfundo__small{
    width: 100px;
    height: 100px;
    object-fit: cover;
}