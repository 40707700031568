html {
    font-size: 22px;
    line-height: 1.55;
    color: #333;

    @media screen and (max-width: 767px) {
        font-size: 16px;
    }

}

body {
    font-family: 'Lato', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Heebo', sans-serif;
    font-weight: 900;
    line-height: 1.55;
}

.h2-standout{
    h2{
        border-top: 1px solid #333;
        border-bottom: 1px solid #333;
        background: #333;
        color: #fff;
        text-align: center;
        padding: 0 20px;
    }
    
}

@media screen and (max-width: 767px) {
    h1 {
        font-size: 1.2rem;
    }

    h2 {
        font-size: 1.16rem;
    }

    h3 {
        font-size: 1.12rem;
    }

    h4 {
        font-size: 1.08rem;
    }

    h5 {
        font-size: 1.04rem;
    }

    h6 {
        font-size: 1.0rem;
    }
}

.tb-margin li{
    margin-bottom: 10px;
}