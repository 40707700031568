header {

    label {
        display: none;
    }

    #toggle {
        display: none;
        
    }

    .mainNav {
        // text-transform: uppercase;
        display: grid;
        grid-template-columns: auto auto;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;

        .logo {
            width: 280px;
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                margin: 0;
                padding: 0;
            }
        }

        .menu {
            display: flex;
            justify-content: space-between;
            background: rgba(255, 255, 255, 1);

            a {
                padding: 7px 10px;
                display: block;
                color: #333;
            }

            a:hover, a.current {
                color: #4081bd;
                border-bottom: 2px #4081bd solid;
            }

            a.current{
                cursor: default;
            }


            .has-submenu {
                a:after {
                    content: ' +';
                }

                &--1:hover {
                    .submenu--1 {
                        display: block;
                    }
                }

                &--2:hover {
                    .submenu--2 {
                        display: block;
                    }
                }
            }

            .submenu {
                display: none;
                position: absolute;
                z-index: 99;
                min-width: 160px;
                background: #fff;
                -webkit-box-shadow: 0 2.598px 4.7px 0.3px rgba(0, 0, 0, 0.12), 0 1.732px 5.88px 0.12px rgba(0, 0, 0, 0.08);
                box-shadow: 0 2.598px 4.7px 0.3px rgba(0, 0, 0, 0.12), 0 1.732px 5.88px 0.12px rgba(0, 0, 0, 0.08);

                a:after {
                    content: '';
                }

                li {
                    border-bottom: 1px solid #ccc;
                }

            }
            .submenu a:hover{
                border-bottom: none;
            }
        }
    }

    @media screen and (max-width: 961px) {
        label {
            display: block;
            cursor: pointer;
            color: #000;
            font-size: 1.5rem;
        }

        .mainNav .menu {
            display: none;
        }

        .mainNav {
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            text-align: center;

            .logo {
                // width: 100%;
                margin: 20px auto;

            }

            .top-level li:first-child {
                border-top: 1px solid #ccc;
            }

            nav {
                margin-top: -15px;
            }
        }

        img {
            display: inline-block;
        }

        #toggle:checked+.menu {
            display: block;
        }

        .mainNav .menu {
            text-align: left;

            a {
                padding: 10px 10%;
            }

            li {
                border-bottom: 1px solid #ccc;
            }

            .has-submenu {
                border: none;
            }

            .submenu {
                position: relative;
                width: 100%;
                display: block;
                box-shadow: none;
                background: #f1f1f1;

                a:before {
                    content: '-- '
                }
            }

            .has-submenu a:after {
                content: '';
            }
        }
    }
}